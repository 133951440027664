<template>
    
    <b-row>
                <b-col lg="4">
                    <div class="card " >
                        <div class="card-body p-4 text-center pb-3 mb-2"  >
                            <div class="mx-auto mb-3"  >
                                <div class="avatar-md"  style="margin: 0px auto;" >
                                    <div class="avatar-title rounded bg-soft-danger text-danger"  >
                                        {{ this.getInitials(form.firstName + " " + form.lastName ) }}
                                    </div>
                                </div>
                            </div>
                            <h5 class="card-title mb-1 fw-semibold" >{{ form.firstName + " " + form.lastName }}</h5>
                            <p class="text-muted mb-0" >{{ form.position }}</p>

                            <span class="fs-12 mt-2 badge bg-info" >{{ $t('newApplication') }}</span>
                        </div>
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <h5 class="card-title mb-3">{{ $t('generalInfo') }}</h5>
                            <div class="table-responsive" >
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="ps-0" scope="row">{{ $t('NameandSurname') }} :</th>
                                            <td class="text-muted">{{ form.firstName + " " + form.lastName }}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row">{{ $t('DateBirth') }}</th>
                                            <td class="text-muted">{{ form.bday }}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row">{{ $t('phone_number') }} :</th>
                                            <td class="text-muted">{{ form.numPhone }}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row">E-mail :</th>
                                            <td class="text-muted">{{ form.email }}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row">{{ $t('address') }} :</th>
                                            <td class="text-muted">{{ form.city + ', ' + form.district + ', ' + form.street }}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row">{{ $t('RequestDate') }}</th>
                                            <td class="text-muted">29.08.2024</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div><!-- end card body -->
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <div class="d-flex align-items-center mb-4" >
                                <div class="flex-grow-1" >
                                    <h5 class="card-title mb-0">{{ $t('ReleaseT') }}</h5>
                                </div>
                                <div class="flex-shrink-0" >
                                    <span class="badge bg-soft-warning text-warning fs-12">{{ $t('Cause') }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap gap-2" >
                                <span>
                                    Халатність до роботи
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <h5 class="card-title mb-4">{{ $t('SocialNetworks') }}</h5>
                            <div class="d-flex flex-wrap gap-2" >

                                <!-- Гіт -->
                                <div v-if="form.social_networks.github" >
                                    <a :href="form.social_networks.github" target="_blank" class="avatar-xs d-block">
                                        <span class="avatar-title rounded-circle fs-16 bg-body text-body">
                                            <i class="ri-github-fill"></i>
                                        </span>
                                    </a>
                                </div>

                                <!-- Телеграм -->
                                <div v-if="form.social_networks.telegram" >
                                    <a :href="form.social_networks.telegram" target="_blank" class="avatar-xs d-block">
                                        <span class="avatar-title rounded-circle fs-16 bg-info">
                                            <i class="ri-telegram-line"></i>
                                        </span>
                                    </a>
                                </div>

                                <!-- Інстаграм -->
                                <div v-if="form.social_networks.instagram">
                                    <a :href="form.social_networks.instagram" target="_blank" class="avatar-xs d-block">
                                        <span class="avatar-title rounded-circle fs-16 bg-danger">
                                            <i class="ri-instagram-line"></i>
                                        </span>
                                    </a>
                                </div>

                                <!-- Фейсбук -->
                                <div v-if="form.social_networks.facebook">
                                    <a :href="form.social_networks.facebook" target="_blank" class="avatar-xs d-block">
                                        <span class="avatar-title rounded-circle fs-16 bg-info">
                                            <i class="ri-facebook-circle-line"></i>
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <h5 class="card-title mb-4">{{ $t('skills') }}</h5>
                            <div class="d-flex flex-wrap gap-2 fs-15" >
                                <template v-for="item in form.skills" :key="item">
                                    <span class="fs-12 badge badge-soft-info text-info">{{ item.label }}</span>
                                </template>
                            </div>
                        </div>
                    </div>

                    
                </b-col>

                <b-col lg="8">

                    <div class="card" >
                        <div class="card-body" >
                            
                            <div class="row" >

                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-user-2-fill"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('Position') }} :</p>
                                            <h6 class="text-truncate mb-0">{{ form.position }}</h6>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-global-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('Website') }} :</p>
                                            <a href="#" class="fw-semibold">{{ form.website }}</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-user-voice-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('WorkExperience') }} :</p>
                                            <h6 class="text-truncate mb-0">{{ form.work_experience }}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-timer-flash-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('TypeofEmployment') }} :</p>
                                            <h6 class="text-truncate mb-0">{{ form.type_of_employment }}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-time-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('WorkSchedule') }} :</p>
                                            <h6 class="text-truncate mb-0">{{ form.work_schedule }}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-6" >
                                    <div class="d-flex mt-4" >
                                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" >
                                                <i class="ri-currency-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden" >
                                            <p class="mb-1">{{ $t('DesiredSalary') }} :</p>
                                            <h6 class="text-truncate mb-0">{{ form.salary }} ₴</h6>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <h5 class="card-title mb-3 mt-4">{{ $t('Comment') }}</h5>
                            <p>{{ form.comment }}</p>
                            
                        </div>
                        
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <div class="d-flex align-items-center mb-4" >
                                <h5 class="card-title flex-grow-1 mb-0">{{ $t('Documents') }}</h5>
                                <div class="flex-shrink-0" >
                                    <input class="form-control d-none" type="file" id="formFile">
                                    <label for="formFile" class="btn btn-danger"><i class="ri-upload-2-fill me-1 align-bottom"></i> {{ $t('downloadFiles') }}</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                                <div class="avatar-sm copy" >
                                    <div class="avatar-title bg-light text-info rounded fs-24" ><i class="ri-video-fill"></i></div>
                                </div>
                                <div class="flex-grow-1 ms-3 copy" >
                                    <h6 class="mb-1"><a>11.mp4</a></h6>
                                    <small class="text-muted">2024-08-29 17:42:03</small>
                                </div>
                                <div class="hstack gap-3 fs-16" >
                                    <button class="copy btn btn-soft-info btn-icon"><i class="ri-download-2-line"></i></button>
                                    <button class="copy btn btn-soft-danger btn-icon "><i class="ri-delete-bin-line"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card" >
                        <div class="card-body" >
                            <div class="d-flex align-items-center mb-4" >
                                <h5 class="card-title flex-grow-1 mb-0">{{ $t('EventsJ') }}</h5>
                            </div>

                            <div class="card mb-0 card-animate" >
                                <div class="card-body" >
                                    <div class="d-lg-flex align-items-center" >
                                        <div class="ms-lg-3 my-3 my-lg-0" >
                                            <a href="pages-profile.html"><h5 class="fs-14 fw-semibold mb-2">{{ $t('Interview') }}</h5></a>
                                            <p class="text-muted mb-0">20.10.2024</p>
                                        </div>
                                        <div class="d-flex gap-1 mt-0 text-muted mx-auto" >
                                            <div ><i class="ri-time-line text-primary me-1 align-bottom"></i> <span class="badge bg-danger-subtle text-danger"></span></div>
                                            <div ><span class="badge bg-info-subtle text-info">10:00-15:00</span></div>
                                        </div>
                                        <div >
                                            <a href="#!" class="btn btn-soft-info">{{ $t('Open') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </b-col>

            </b-row>

</template>

<script>

export default {
    props: ['objCard'],
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        getInitials(string) {
            // Отримання ініціалів з імені користувача

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
    }
    
}
</script>