<template>
    <dialogBox >
        <template v-slot:title>{{ $t('ReleaseT') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h3 class="mb-3"><b>{{ $t('SpecifytheReasonforDismissal') }}</b></h3>
                <textarea class="form-control"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Add') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default{
    props: ['objCard'],
    components: {
        dialogBox,
    },
    data(){
        return{
            form: {
            },
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata() {
            this.form = this.objCard
        }
    }
}
</script>