<template>
    <dialogBox >
        <template v-slot:title>{{ $t('ChangeStatus') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h3 class="mb-3"><b>{{ $t('ChooseNewStatus') }}</b></h3>
                <span class="text-muted fs-12 mb-3">{{ $t('SelectCandidateStatus') }}</span>
                <b-form-select class="mt-3" v-model="form.status" :options="options"></b-form-select>

                <div class="mt-3">
                    <label >{{ $t('Comment') }}</label>
                    <textarea class="form-control"></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default{
    props: ['objCard'],
    components: {
        dialogBox,
    },
    data(){
        return{
            form: {
                status: ''
            },
            options: [
                { value: 'new_request', text: this.$t('newApplication') }, // Нова заявка
                { value: 'will_interview', text: this.$t('AnInterviewhasBeenScheduled') },  // Назначена співбесіда
                { value: 'denied', text: this.$t('RefusedJ') },  // Відмовлено
                { value: 'under_question', text: this.$t('InQuestion') },  // Під питанням
                { value: 'intern', text: this.$t('Internship') },  // Стажується
                { value: 'not_pass_internship', text: this.$t('DidNotPassInternship') }, // Не пройшов стажування
                { value: 'works', text: this.$t('open') },  // Працює
                { value: 'released', text: this.$t('open') }  // Звільнено
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata() {
            this.form = this.objCard
        }
    }
}
</script>