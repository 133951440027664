<template>

    <modal :title="form.firstName + ' ' + form.lastName" @close="this.$emit('close')">

        <template v-slot:head-button>

            <div class="dropdown">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">

                    <button 
                        class="dropdown-item"   
                        
                    >
                        {{ $t('ScheduleanInterview') }}
                    </button>
                    <!-- 
                        Редагування завдання 
                        Є перевірка:
                        - права доступу:
                            * юзер має повний доступ
                            * юзер може брати завданн в роботу 
                        - статус завдання:
                            * завдання має статус "новий"
                            * завдання має статус "чекає виконання"
                            * завдання має статус "чекає підтвердження"
                    -->
                    <button 
                        class="dropdown-item"   
                        
                    >{{ $t('toEditAp') }}</button>
                </div>
            </div>

        </template>

        <template v-slot:body>


            <div>
                <!-- Вкладка з табами -->
                <b-tabs 
                    v-model="this.tabIndex"
                    pills 
                    content-class="mt-3"
                >

                    <b-tab :title="$t('Main')" active>
                        <!-- Головний таб -->
                        <mainTab :objCard="this.form" />
                    </b-tab>
                    
                    <!-- Таб з таймлайном -->
                    <b-tab :title="$t('Таймлайн')" >
                        <timelineTab :objCard="this.form" />
                    </b-tab>
            
                </b-tabs>

            </div>

        </template>

        <template v-slot:footer-bottom >

            <button 
                type="submit"
                class="btn btn-info"
                @click="showChangeStatus.show = true"
            >
                {{ $t('ChangeStatus') }}
            </button>

            <button 
                type="submit"
                class="btn btn-danger"
                @click="showReleaseWorker.show = true"
            >
                {{ $t('Release') }}
            </button>

        </template>

    </modal>

    <!-- Модальне вікно змінити статусу -->
    <changeStatusBox
        v-if="showChangeStatus.show"
        @close="showChangeStatus.show = false"
        :objCard="this.form"
    />
    
    <!-- Модальне вікно звільнення співробітника -->
    <releaseWorkerBox 
        v-if="showReleaseWorker.show"
        @close="showReleaseWorker.show = false"
        :objCard="this.form"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import mainTab from './activity/main/index.vue';
import timelineTab from './activity/timeline/index.vue';
import changeStatusBox from './actions/changeStatus'
import releaseWorkerBox from './actions/releaseWorker';

export default {
    props: ['objCard'],
    components: {
        modal,
        mainTab,
        timelineTab,
        changeStatusBox,
        releaseWorkerBox
    },
    data(){
        return {
            form: '',
            showChangeStatus: {
                data: '',
                show: false
            },
            showReleaseWorker: {
                data: '',
                show: false
            }
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        getInitials(string) {
            // Отримання ініціалів з імені користувача

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
    }

}
</script>