<template> 

    <modal :title="'Додати кандидата'">

        <template v-slot:body>
            <div>
                <h5 class="mb-1 fw-semibold">{{ $t('Main') }}</h5>
                <p class="text-muted mb-2">{{ $t('GeneralInfotheCandidate') }}</p>
            </div>
            <b-card>
                <div class="row" >
                    
                    <b-form-group :label="$t('type')" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="radio-group-1"
                            v-model="form.typeCandidate"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>

                    <!-- Компанія кандидата -->
                    <div class="col-lg-12" v-if="form.typeCandidate == 'outsourcing'" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('company') }}</label>
                            <input type="text" class="form-control" v-model="form.companyTitle">
                        </div>
                    </div>

                    <!-- Ім'я кандидата -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('name') }}</label>
                            <input type="text" class="form-control" v-model="form.companyTitle">
                        </div>
                    </div>
                    
                    <!-- Прізвище кандидата -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="lastnameInput" class="form-label">{{ $t('LastName') }}</label>
                            <input type="text" class="form-control" v-model="form.lastName">
                        </div>
                    </div>

                    <!-- Номер телефону -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="phonenumberInput" class="form-label">{{ $t('phone_number') }}</label>
                            <input type="text" class="form-control" v-model="form.numPhone">
                        </div>
                    </div>

                    <!-- Пошта -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="emailInput" class="form-label">Email</label>
                            <input type="email" class="form-control" v-model="form.email">
                        </div>
                    </div>

                    <!-- Дата народження -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="websiteInput1" class="form-label">{{ $t('DateBirth') }}</label>
                            <input type="text" class="form-control" v-model="form.bday">
                        </div>
                    </div>

                    <!-- Веб-сайт -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="websiteInput1" class="form-label">{{ $t('Website') }}</label>
                            <input type="text" class="form-control" v-model="form.website">
                        </div>
                    </div>

                    <!-- Місто -->
                    <div class="col-lg-4" >
                        <div class="mb-3" >
                            <label for="cityInput" class="form-label">{{ $t('city') }}</label>
                            <input type="text" class="form-control" v-model="form.city" >
                        </div>
                    </div>

                    <!-- Район -->
                    <div class="col-lg-4" >
                        <div class="mb-3" >
                            <label for="countryInput" class="form-label">{{ $t('District') }}</label>
                            <input type="text" class="form-control" v-model="form.district" >
                        </div>
                    </div>

                    <!-- Вулиця -->
                    <div class="col-lg-4" >
                        <div class="mb-3" >
                            <label for="zipcodeInput" class="form-label">{{ $t('street') }}</label>
                            <input type="text" class="form-control" v-model="form.street">
                        </div>
                    </div>
                    
                </div>
            </b-card>

            <div class="card" >
                <div class="card-body" >
                    <div class="d-flex align-items-center mb-4" >
                        <div class="flex-grow-1" >
                            <h5 class="card-title mb-0">{{ $t('SocialNetworks') }}</h5>
                        </div>
                        <!-- <div class="flex-shrink-0" >
                            <a class="badge bg-light text-primary fs-12"><i class="ri-add-fill align-bottom me-1"></i> Додати</a>
                        </div> -->
                    </div>
                    <div class="mb-3 d-flex" >
                        <div class="avatar-xs d-block flex-shrink-0 me-3" >
                            <span class="avatar-title rounded-circle fs-16 bg-body text-body">
                                <i class="ri-github-fill"></i>
                            </span>
                        </div>
                        <input type="email" class="form-control" placeholder="https://git">
                    </div>
                    <div class="mb-3 d-flex" >
                        <div class="avatar-xs d-block flex-shrink-0 me-3" >
                            <span class="avatar-title rounded-circle fs-16 bg-danger">
                                <i class="ri-instagram-line"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="https://instagram.com/">
                    </div>
                    <div class="mb-3 d-flex" >
                        <div class="avatar-xs d-block flex-shrink-0 me-3" >
                            <span class="avatar-title rounded-circle fs-16 bg-info">
                                <i class="ri-telegram-line"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="https://t.me/">
                    </div>
                    <div class="d-flex" >
                        <div class="avatar-xs d-block flex-shrink-0 me-3" >
                            <span class="avatar-title rounded-circle fs-16 bg-info">
                                <i class="ri-facebook-circle-line"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="https://facebook.com/">
                    </div>
                </div>
            </div>

            <div>
                <h5 class="mb-1 fw-semibold">{{ $t('ByVacancy') }}</h5>
                <p class="text-muted mb-2">{{ $t('InformationonVacancies') }}</p>
            </div>

            <b-card>
                <div class="row" >

                    <!-- Посада -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('Position') }}</label>
                            <!-- <input type="text" class="form-control" v-model="form.position"> -->
                            <b-form-select  v-model="form.position" :options="optionsPositions"></b-form-select>
                        </div>
                    </div>

                    <!-- Досвід роботи -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('WorkExperience') }}</label>
                            <!-- <input type="text" class="form-control" v-model="form.work_experience" > -->
                            <b-form-select  v-model="form.work_experience" :options="optionsWorkExperience"></b-form-select>
                        </div>
                    </div>

                    <!-- Тип зайнятості -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('TypeofEmployment') }}</label>
                            <!-- <input type="text" class="form-control" v-model="form.type_of_employment" > -->
                            <b-form-select v-model="form.type_of_employment" :options="optionsTypeEmployment"></b-form-select>
                        </div>
                    </div>

                    <!-- Графік роботи -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('WorkSchedule') }}</label>
                            <input type="text" class="form-control" v-model="form.work_schedule">
                        </div>
                    </div>

                    <!-- Бажана заробітна плата -->
                    <div class="col-lg-6" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('DesiredSalary') }}</label>
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="form.salary" >
                                <span class="input-group-text">₴</span>
                            </div>
                        </div>
                    </div>

                    <!-- скіли -->
                    <div class="col-lg-12" >
                        <div class="mb-3" >
                            <label for="firstnameInput" class="form-label">{{ $t('skills') }}</label>
                            <Multiselect 
                                :close-on-select="true"
                                :searchable="true"
                                v-model="form.skills"
                                mode="tags"
                                :create-option="true"
                                :options="tagsList"
                            />  
                        </div>
                    </div>

                    <!-- Коментар від кандидата-->
                    <div class="col-lg-12" >
                        <div class="mb-3 pb-2" >
                            <label for="exampleFormControlTextarea" class="form-label">{{ $t('Comment') }}</label>
                            <textarea class="form-control" v-model="form.comments" rows="3"></textarea>
                        </div>
                    </div>

                </div>
            </b-card>

            <div>
                <h5 class="mb-1 fw-semibold">{{ $t('ResumeandOtherFiles') }}</h5>
                <p class="text-muted mb-2">{{ $t('ResumeandOtherFilesDesc') }}</p>
            </div>
            <b-card>
                <files 
                    :obj="files" 
                    :objFiles="files" 
                    @uploadFiles="uploadFiles"
                    @downloadFile="downloadFile"
                    @deleteFile="deleteFile"
                    @getFile="getFile"
                />
            </b-card>

        </template>

        <template v-slot:footer-bottom >
            <button type="submit" class="btn btn-success">{{ $t('Add') }}</button>
        </template>

    </modal>
    
</template>

<script>
import modal from '@/components/modal/modal-lg'
import files from '@/components/files/index'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
    components: {
        modal,
        files,
        Multiselect
    },
    data() {
        return {
            form: {
                typeCandidate: 'full_time_employee', // тип
                img: '', // аватар
                companyTitle: '', // назва компанії
                firstName: '', // ім'я кандидата
                lastName: '', // Прізвище кандидата
                numPhone: '', // Номер телефону кандидата
                website: '', // веб-сайт
                email: '', // Пошта кандидата
                bday: '', // Дата народження
                city: '', // Місто
                district: '', // Район
                street: '', // Вулиця
                position: '', // Посада
                work_experience: '', // Досвід роботи
                type_of_employment: '', // Тип зайнятості (фріланс, фулл тайм, часткова і тд)
                work_schedule: '', // Графік роботи
                salary: '', // Заробітна плата
                skills: [], // Навички
                social_networks: {
                    instagram: '',
                    facebook: '',
                    telegram: '',
                    github: ''
                }, // соціальні мережі
                comment: "" // Коментар
            },
            files: [],
            selected: 'full_time_employee',
            options: [
                { text: this.$t('FulltimeEmployee'), value: 'full_time_employee' },
                { text: this.$t('Outsourcing'), value: 'outsourcing' },
            ],
            tagsList: [
                { label: this.$t('JavaScript'), value: 'js' },
                { label: this.$t('PHP'), value: 'php' },
            ],
            optionsPositions: [
                { value: 'accountant', text: this.$t('bookkeeper') }, // Бухгалтер
                { value: 'manager', text: this.$t('Manager') }, // Менеджер
                { value: 'developer', text: this.$t('Developer') }, // Розробник
                { value: 'qa', text: this.$t('QA') }, // Тестувальний ПЗ
            ],
            optionsWorkExperience: [
                { value: 'no_experience', text: this.$t('no_experience') }, // Без досвіду
                { value: 'half_year', text: this.$t('half_year') }, // Півроку
                { value: 'from_one_to_three_years', text: this.$t('from_one_to_three_years') }, // Від 1 до 3 років
                { value: 'from_three_to_five_years', text: this.$t('from_three_to_five_years') }, // Від 3 до 5 років
                { value: 'from_five_years', text: this.$t('from_five_years') }, // Від 5 років
            ],
            optionsTypeEmployment: [
                { value: 'full_employment', text: this.$t('full_employment') }, // Повна зайнятість
                { value: 'partial_employment', text: this.$t('partial_employment') }, // Часткова
                { value: 'remote_work', text: this.$t('remote_work') }, // Віддалена 
            ]
        }
    },
}
</script>
